import { MWorkspace } from '../modeles';
import { BaseService } from './base.service';

class WorkspaceService extends BaseService {
  PATH = 'workspace/';

  async list(builderId: string): Promise<MWorkspace[]> {
    return this._listing(`${this.PATH}list/${builderId}`);
  }

  async add(builderId: string, name: string): Promise<MWorkspace | null> {
    return this._add(`${this.PATH}add/${builderId}`, {
      name,
    } as MWorkspace);
  }

  async edit(id: number, name: string): Promise<MWorkspace | null> {
    return this._add(`${this.PATH}edit/${id}`, {
      name,
    } as MWorkspace);
  }
}

export const workspaceService = new WorkspaceService();
