import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { authsService } from '../services/auths.service';
import { MDiscord } from '../modeles';

class UserAuthAccount {
  id: number = 0;
  name: string = '';
  isFree: boolean = true;
}

export class UserAuth {
  id: number = 0;
  accountId: number = 0;
  email: string = '';
  isAdmin: boolean = false;
  isFreeAccount: boolean = true;
  isSuperAdmin: boolean = false;
  Account: UserAuthAccount = new UserAuthAccount();
}

export interface AuthsContextType {
  user: UserAuth | null;
  discord: MDiscord | null;
  signUp: boolean;
  _setUser: React.Dispatch<React.SetStateAction<UserAuth | null>>;
  setDiscord: (discord: MDiscord) => void;
  isLogin: () => boolean;
  isAdmin: () => boolean;
  isReady: () => boolean;
  isFreeAccount: () => boolean;
  isSuperAdmin: () => boolean;
  signup: (data: any) => Promise<void>;
  login: (data: any) => Promise<void>;
  autologin: () => Promise<void>;
  logout: () => Promise<void>;
}

const AuthsContext = React.createContext<AuthsContextType | null>(null);

export function AuthsContextProvider({ children }: { children: React.ReactNode }) {
  const [user, _setUser] = useState<UserAuth | null>(null);
  const [discord, _setDiscord] = useState<MDiscord | null>(null);
  const [ready, _setReady] = useState(false);
  const [signUp, _setSignUp] = useState<boolean>(false);

  const uHistory = useHistory();
  const uLocation = useLocation();

  // TODO remove eslint-disable
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value: AuthsContextType = {
    user,
    discord,
    signUp,
    _setUser,

    isLogin() {
      return !!user;
    },

    isReady() {
      return ready;
    },

    isAdmin() {
      // eslint-disable-next-line react/no-this-in-sfc
      return this.isLogin() && !!user?.isAdmin;
    },

    isSuperAdmin() {
      // eslint-disable-next-line react/no-this-in-sfc
      return this.isLogin() && !!user?.isSuperAdmin;
    },

    isFreeAccount() {
      // eslint-disable-next-line react/no-this-in-sfc
      return this.isLogin() && !!user?.Account.isFree;
    },

    async signup(data: any) {
      authsService.setToken('');
      const newUser = await authsService.signup(data);
      if (newUser != null) {
        _setUser(newUser);
        uHistory.push('/');
      } else if (authsService.getToken()) {
        _setSignUp(true);
      }
    },

    async login(data: any) {
      const newUser = await authsService.login(data);
      if (newUser != null) {
        _setUser(newUser);
        uHistory.push('/');
      }
    },

    setDiscord(data: MDiscord) {
      _setDiscord(data);
      localStorage.setItem('discord', JSON.stringify(data));
    },

    async autologin() {
      const newUser = await authsService.autologin();
      if (newUser) {
        _setUser(newUser);
        if (uLocation.pathname === '/') {
          uHistory.push('/');
        }
      } else {
        const split = uLocation.pathname.split('/');
        const page = split?.[1] || '';
        if (!['', 'home', 'builder'].includes(page)) {
          uHistory.push('/');
        }
      }
      _setReady(true);
    },
    async logout() {
      await authsService.logout();
      _setUser(null);
    },
  };

  useEffect(() => {
    value.autologin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthsContext.Provider value={value}>
      {children}
    </AuthsContext.Provider>
  );
}

export function useAuths() {
  const context = React.useContext(AuthsContext);
  if (!context) throw new Error('No AuthsContext provider found!');
  return context;
}
