import {
  Slider,
} from 'antd';

import './modal-variant.scss';
import { MPromptOption } from '../../../../../modeles/promptOption.m';

// eslint-disable-next-line react/no-unused-prop-types
export function ModalVariant(props: { option: MPromptOption, updateData: () => void, className?: string; }) {
  const editVariant = (value: number) => {
    props.option.elements![0].intensity = value;
    props.option.elements![0].isSelected = value > 0;
    props.updateData();
  };

  return (
    <div id="modal-variant" className={props.className}>
      <div className="simulation">
        <div className="top">
          <div className="circle">1</div>
          <div className="square">2</div>
        </div>
        <div className="bottom">
          <div className="diamond">3</div>
          <div className="triangle">4</div>
        </div>
      </div>
      <div className="variant-slider">
        <p>Similar</p>
        <Slider className="slider" defaultValue={0} onChange={(e: number) => editVariant(e)} />
        <p>Different</p>
      </div>
    </div>
  );
}
