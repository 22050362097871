import { BuilderTypes } from '../enums';
import { MWorkspace } from './workspace.m';

export class MBuilder {
  id: number = 0;
  name: string = '';
  type: BuilderTypes = BuilderTypes.MIDJOURNEY;
  config = {};
  needReload = false;
  WorkspaceDefault: MWorkspace = new MWorkspace();
  Workspaces: MWorkspace[] = [];
}
