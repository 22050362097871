import { useState } from 'react';
import {
  Badge, Button, Image, Modal,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { MPromptOption } from '../../../../modeles/promptOption.m';
import { usePromptOption } from '../../contexts/prompt-option.context';

import './prompt-options.scss';
import { ModalElements } from '../prompt-options-modals/modal-elements';
import { ModalStyles } from '../prompt-options-modals/modal-styles';
import { ModalVariant } from '../prompt-options-modals/modal-variant';

export function PromptOptions(props: { className?: string; }) {
  const uPromptOption = usePromptOption();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [optionSelected, setOptionSelected] = useState<MPromptOption>();

  const showModal = (option: MPromptOption) => {
    setOptionSelected(option);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const editOption = () => {
    uPromptOption.setPromptOptions([...uPromptOption.promptOptions]);
  };

  const getModal = () => {
    switch (optionSelected?.name) {
      case 'Elements':
        return <ModalElements option={optionSelected} updateData={editOption} />;
      case 'Variants':
        return <ModalVariant option={optionSelected} updateData={editOption} />;
      default:
        return <ModalStyles option={optionSelected!} updateData={editOption} />;
    }
  };

  const modalTitle = () => {
    return (
      <div className="prompt-options-modal">
        <div className="title">
          <Image
            width={24}
            src={optionSelected?.icon}
            preview={false}
          />
          <h2>{optionSelected?.name}</h2>
        </div>
        <p className="description">{optionSelected?.description}</p>
      </div>
    );
  };

  return (
    <div id="prompt-options" className={props.className}>

      <div className="options">
        <div className="row">
          {
            uPromptOption.promptOptions.map((option: MPromptOption, index) => (
              <Badge
                className="col-xl-4 col-lg-6 col-2"
                count={uPromptOption.selectedElements(option).length}
                key={index}
                offset={[-15, 15]}
                overflowCount={9}
                color="#0068F7"
              >

                <div
                  className={`card-option ${option.enabled ? '' : 'disabled'} ${uPromptOption.selectedElements(option).length > 0 ? 'selected' : ''}`}
                  onClick={() => showModal(option)}
                >
                  <Image
                    width={24}
                    src={option.icon}
                    preview={false}
                  />
                  <p className="title">{option.name}</p>
                </div>

              </Badge>
            ))
          }

        </div>
      </div>
      <Button className="clear-button" icon={<DeleteOutlined />} type="dashed" onClick={() => uPromptOption.clearAll()}>Clear all option</Button>
      <Modal title={modalTitle()} width={1100} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className="test">
        {
          getModal()
        }
      </Modal>
    </div>
  );
}
