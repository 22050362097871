import { MPromptOptionElement } from '../modeles/promptOption.m';
import { BaseService } from './base.service';

class PromptOptionService extends BaseService {
  PATH = 'prompt-option/';

  async getPromptOptionElement(): Promise<MPromptOptionElement[] | null> {
    return this._view(`${this.PATH}get-prompt-option-element`);
  }
}

export const promptOptionService = new PromptOptionService();
