import { useBuilder } from '../../../../contexts/builder.context';
import { CreativePreview } from '../creative-preview';

import './creatives-preview.scss';

export function CreativesPreview(props: { className?: string; handleScroll: () => void }) {
  const uBuilder = useBuilder();

  return (
    <div id="creatives-preview" className={props.className}>
      <div id="creatives-previews" onScroll={props.handleScroll} className="previews row">
        {uBuilder.workspace?.Creations.map((creation) => (
          <CreativePreview creation={creation} key={creation.id} />
        ))}
      </div>
    </div>
  );
}
