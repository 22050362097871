import {
  Slider, Image,
} from 'antd';
import {
  EyeOutlined, CheckOutlined,
} from '@ant-design/icons';

import './modal-styles.scss';
import _ from 'lodash';
import { MPromptOption, MPromptOptionElement } from '../../../../../modeles/promptOption.m';

export function ModalStyles(props: { option: MPromptOption, updateData: () => void, className?: string; }) {
  const onChange = (newValue: number, option: MPromptOptionElement) => {
    option.intensity = newValue;
    props.updateData();
  };

  const editOptionSelected = (option: MPromptOptionElement) => {
    if (!props.option.isMultipleSelection) {
      props.option.elements.filter((el) => el.name !== option.name).forEach((element) => {
        element.isSelected = false;
      });
    }
    option.isSelected = !option.isSelected;
    props.updateData();
  };

  return (
    <div id="modal-styles" className={props.className}>

      <div className="row listbt">
        {
          _.sortBy(props.option?.elements, (el) => el.value).map((element, index) => (

            <div className="elements mb-4 col-lg-2 col-md-3 col-sm-4 col-6 ">
              <div className="element-card" key={index} onClick={() => { editOptionSelected(element); }}>
                <Image
                  className="image"
                  src={element.image}
                  preview={false}
                />
                <div className={`${element.isSelected ? 'hide' : 'name'}`}>
                  <span>
                    {element.name}
                  </span>
                </div>
                <div className={`${element.isSelected ? 'selected' : 'hide'}`}>
                  <CheckOutlined style={{ fontSize: '18px' }} />
                  <span>
                    {element.name}
                  </span>
                  {
                    props.option.hasIntensity && (
                      <div className="slider-value" onClick={(e) => e.stopPropagation()}>
                        <EyeOutlined />
                        <Slider
                          className="slider"
                          min={0}
                          max={1}
                          step={0.01}
                          value={element.intensity}
                          onChange={(e: number) => {
                            onChange(e, element);
                          }}
                        />
                      </div>
                    )
                  }
                </div>
              </div>
            </div>

          ))
        }

      </div>
    </div>
  );
}
