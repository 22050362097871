/* eslint-disable jsx-a11y/control-has-associated-label */

import {
  Button, Divider, Form, Image, Input, Select, Space,
} from 'antd';
import { useEffect, useState } from 'react';

import { Inspector } from '../../assets/images/help';

import './builder-form-midjourney.scss';
import { midjourneyService } from '../../services';

interface ChannelType {
  id: number;
  name: string;
}

interface GuildType {
  id: number;
  name: string;
}

export function BuilderFormMidjourney(props: {
  form: any
  step: number,
  setConfigDetail: (data: object) => void
}) {
  const [guilds, _setGuilds] = useState<ChannelType[] | null>(null);
  const [channels, _setChannels] = useState<ChannelType[] | null>(null);
  const [userToken, _setUserToken] = useState<string>('');
  const [isCredentials, _setIsCredentials] = useState<boolean>(true);

  useEffect(() => {
    if (props.step === 0) {
      _setGuilds(null);
      _setChannels(null);
      _setUserToken('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.step]);

  return (
    <>
      <b><u>Midjourney:</u></b>
      {
        isCredentials
          ? (
            <>
              <Form.Item
                label="Discord login"
                name="discordLogin"
              >
                <Input placeholder="discord login" disabled={guilds != null || channels != null} />
              </Form.Item>
              <Form.Item
                label="Discord password"
                name="discordPassword"
              >
                <Input placeholder="discord password" disabled={guilds != null || channels != null} />
              </Form.Item>
              <Button
                type="primary"
                onClick={() => {
                  const { discordLogin: login, discordPassword: password } = props.form.getFieldsValue() as {
                    discordLogin: string,
                    discordPassword: string,
                  };
                  midjourneyService.login({ login, password }).then((token: string | null) => {
                    if (token) {
                      _setUserToken(token);
                      props.setConfigDetail({ userToken: token });
                      midjourneyService.guilds(token).then((glds: GuildType[] | null) => {
                        if (glds !== null && glds.length) {
                          _setGuilds(glds);
                        }
                      });
                    }
                  });
                }}
              >Test connection
              </Button>
            </>
          )
          : (
            <Form.Item
              rules={[{
                required: true,
                message: 'User token is required',
              },
              ]}
              label="User Token"
              name={['config', 'userToken']}
              tooltip={(<Image width={400} src={Inspector} />)}
            >
              <Space.Compact style={{ width: '100%' }}>
                <Input
                  placeholder="MTA5MTI2NTc0Mzg...."
                  value={userToken}
                  onChange={(e) => { _setUserToken(e.target.value as string); }}
                  disabled={guilds != null || channels != null}
                />
                <Button
                  type="primary"
                  style={{ width: '30%' }}
                  onClick={() => {
                    midjourneyService.guilds(userToken).then((glds: GuildType[] | null) => {
                      if (glds !== null && glds.length) {
                        _setGuilds(glds);
                      }
                    });
                  }}
                >Check token
                </Button>
              </Space.Compact>
            </Form.Item>
          )
      }
      <Button
        onClick={() => { _setIsCredentials(!isCredentials); }}
        type="link"
      >{isCredentials ? 'Connnect with User token' : 'Connnect with credentials'}
      </Button>
      <Divider />
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Guild Id is required',
          },
        ]}
        label="Guild Id"
        name={['config', 'guildId']}
      >
        <Select
          placeholder="Guild"
          disabled={guilds == null}
          onChange={() => {
            const config = props.form.getFieldsValue().config as {
              guildId: string,
            };
            midjourneyService.channels(userToken, config.guildId).then((chs: ChannelType[] | null) => {
              if (chs !== null && chs.length) {
                _setChannels(chs);
              }
            });
          }}
        >
          {guilds && guilds.map((g) => (
            <Select.Option key={g.id} value={`${g.id}`}>{g.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Space.Compact style={{ width: '100%' }}>
        <Form.Item
          style={{ width: '100%' }}
          rules={[
            {
              required: true,
              message: 'Channel Id is required',
            },
          ]}
          label="Channel Id"
          name={['config', 'channelId']}
        >
          <Select placeholder="Channel" disabled={channels == null}>
            {channels && channels.map((c) => (
              <Select.Option key={c.id} value={`${c.id}`}>{c.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Space.Compact>
    </>
  );
}
