/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, useCallback, useEffect } from 'react';

import { usePromptOption } from './prompt-option.context';
import { useBuilder } from '../../../contexts';

export interface PromptContextType {
  // prompt
  prompt: string,
  complexPrompt: string | null,
  setPrompt: (prompt: string) => void;
}

const PromptContext = React.createContext<PromptContextType>({
  prompt: '',
  complexPrompt: null,
  setPrompt: (/* prompt: string */) => {},
});

export function PromptContextProvider({ children }: { children: React.ReactNode }) {
  const uBuilder = useBuilder();
  const uPromptOption = usePromptOption();

  const [prompt, _setPrompt] = useState<string>('');
  const [complexPrompt, _setComplexPrompt] = useState<string | null>(null);

  useEffect(() => {
    const newComplexPrompt = (`${uPromptOption.optionBeforePrompt} ${prompt} ${uPromptOption.optionAfterPrompt}`).replace(/\s+/g, ' ').trim();
    _setComplexPrompt(newComplexPrompt);
    uBuilder.setPrompt(newComplexPrompt || '');
  }, [complexPrompt, prompt, uBuilder, uPromptOption.optionAfterPrompt, uPromptOption.optionBeforePrompt]);

  const setPrompt = useCallback((data: string) => {
    _setPrompt(data.replace(/\s+/g, ' ').trim());
  }, []);

  const value = {
    prompt,
    complexPrompt,
    setPrompt,
  };

  return (
    <PromptContext.Provider value={value}>
      {children}
    </PromptContext.Provider>
  );
}

export function usePrompt() {
  const context = React.useContext(PromptContext);
  if (!context) throw new Error('No PromptContext provider found!');
  return context;
}
