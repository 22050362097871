import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';

// import * as ImagesSignup from '../../../../assets/images/sign-up';
import { useAuths } from '../../../../contexts/auths.context';

import '../../index.scss';
import './login.page.scss';

export function LoginPage() {
  const uAuths = useAuths();

  const onFinish = (data: any) => {
    uAuths.login(data);
  };

  return (
    <div className="root-login">
      <div className="block-bkg">
        <div className="block-auth">
          <h1>Login</h1>
          <Form onFinish={onFinish} layout="vertical">
            <Form.Item label="Email" name="email" rules={[{ required: true }]} initialValue="">
              <Input placeholder="Email" type="email" />
            </Form.Item>
            <Form.Item label="Password" name="password" rules={[{ required: true }]} initialValue="">
              <Input.Password placeholder="Password" type="password" />
            </Form.Item>
            {/* <Link
              to="/forgot-password"
              style={{
                marginLeft: '0px', display: 'block', marginTop: '-20px', marginBottom: '20px',
              }}
            >Forgot Password
            </Link> */}
            <Form.Item style={{ marginBottom: '0px' }}>
              <Button type="primary" htmlType="submit">Login</Button>
              <Link className="pl-4" to="/sign-up">Sign Up</Link>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}
