import {
  Button, Form, Input, Modal, Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';

import { useBuilder } from '../../contexts/builder.context';
import { MWorkspace } from '../../modeles';

import './workspace-list.scss';
import { workspaceService } from '../../services';

export function WorkspaceList() {
  const uBuilder = useBuilder();
  const [addWorkspace, _setAddWorkspace] = useState(false);
  const [editedWorkspace, _setEditedWorkspace] = useState<MWorkspace | null>();
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();

  const onFinish = (data: any) => {
    uBuilder.addWorkspace(data.name as string).then((wk: MWorkspace | null) => {
      if (wk) {
        formAdd.resetFields();
        _setAddWorkspace(false);
      }
    });
  };

  const onFinishEdit = (data: any) => {
    if (formAdd.getFieldsError().length === 0) {
      workspaceService.edit(editedWorkspace!.id, data.name as string).then(() => {
        uBuilder.builder!.Workspaces.find((b) => b.id === editedWorkspace?.id)!.name = data.name;
        _setEditedWorkspace(null);
      });
      formEdit.resetFields();
    }
  };

  return (
    <div id="workspace-list">
      {uBuilder.builder && uBuilder.builder.Workspaces.map((w) => {
        return (
          <div className={`workspace ${w.id === uBuilder.workspace?.id ? ' active' : ''}`} key={w.id}>
            <Tooltip title="Edit" className="edit-button">
              <Button shape="circle" icon={<EditOutlined />} onClick={() => { _setEditedWorkspace(w); }} />
            </Tooltip>
            <Link
              className="link"
              key={w.id}
              to={`/builder/${uBuilder.builder?.id}/${w.id}`}
            >
              {w.name}
            </Link>
          </div>
        );
      })}
      <div className="workspace add" onClick={() => { _setAddWorkspace(true); }}>
        <PlusOutlined style={{ fontSize: '37px', color: '#0068F7' }} />
      </div>
      <Modal
        title="Add workspace"
        open={addWorkspace}
        onCancel={() => { _setAddWorkspace(false); formAdd.resetFields(); }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Form layout="vertical" onFinish={onFinish} form={formAdd}>
          <Form.Item
            rules={[{
              required: true,
              message: 'Name is required',
            },
            ]}
            label="Name"
            name="name"
          >
            <Input placeholder="" />
          </Form.Item>
          <Button type="primary" htmlType="submit">Create</Button>
        </Form>
      </Modal>
      <Modal
        title="Edit workspace"
        width={300}
        open={!!editedWorkspace}
        onCancel={() => { _setEditedWorkspace(null); formEdit.resetFields(); }}
        footer={[]}
      >
        <Form layout="vertical" onFinish={onFinishEdit} key={editedWorkspace?.id}>
          <Form.Item
            rules={[{
              required: true,
              message: 'Name is required',
            },
            ]}
            label="Name"
            name="name"
            initialValue={editedWorkspace?.name}
          >
            <Input placeholder="" />
          </Form.Item>
          <Button key="back" onClick={() => { _setEditedWorkspace(null); formEdit.resetFields(); }}>
            Cancel
          </Button>
          <Button key="submit" type="primary" loading={false} htmlType="submit">
            Save
          </Button>
        </Form>
      </Modal>
    </div>
  );
}
