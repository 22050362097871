/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'antd/es/modal/Modal';
import { Button, Form, Input } from 'antd';

import { EditOutlined } from '@ant-design/icons';
import { useAuths } from '../../contexts/auths.context';
import { YcDropdownMenu } from '../dropdown-menu';
import { BuilderAdd } from '../builder-add';

// styles
import './header.scss';
import { useBuilders } from '../../contexts';
import { useBuilder } from '../../contexts/builder.context';
import { MBuilder } from '../../modeles';

export function YcHeader(props: { isHomePath: boolean }) {
  const uAuths = useAuths();
  const uBuilders = useBuilders();
  const uBuilder = useBuilder();
  const [form] = Form.useForm();
  const [builderSelected, _setBuilderSelected] = useState<MBuilder | null>(null);
  const [newConfBuilder, _setNewConfBuilder] = useState<any>('');

  // Collapse menu
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  useEffect(() => {
    if (uAuths.isLogin()) {
      uBuilders.loadBuilders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uAuths]);

  return (
    <nav id="header-component" className={`navbar navbar-expand-lg ${props.isHomePath ? 'tranparent' : ''}`}>
      <div className="hearder-container">
        <Link to="/">
          <img className="logo" title="Starliva" alt="Starliva" src="/logo-starliva.svg" />
        </Link>

        <div className="links">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="#navbarSupportedContent"
            aria-expanded={!isNavCollapsed}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className={`${isNavCollapsed ? 'collapse' : ''} mobilenav navbar-collapse`} id="navbarSupportedContent">
            <div className="navbar-nav">
              {uAuths.isLogin() === true
                && (
                  <>
                    {uBuilders.builders.map((b) => {
                      return (
                        <li className="nav-item" key={b.id}>
                          <Link className={`b-builder ${b.id === uBuilder.builder?.id ? 'active' : ''}`} to={`/builder/${b.id}`}>
                            <img width="30px" src={`/images/builders/${b.type.toLowerCase()}.png`} alt={b.type} />
                            &nbsp;
                            {b.name}
                            <Button
                              className="edit"
                              icon={<EditOutlined />}
                              shape="circle"
                              onClick={(e) => {
                                _setBuilderSelected(b);
                                e.preventDefault();
                              }}
                            />
                          </Link>
                        </li>
                      );
                    })}

                    <li className="nav-item">
                      <a
                        className="b-builder add"
                        href="."
                        role="button"
                        onClick={(event) => {
                          uBuilders.setIsAddingBuilder(true);
                          event.preventDefault();
                        }}
                      >
                        +
                      </a>
                      <Modal
                        title="Add builder"
                        open={uBuilders.isAddingBuilder}
                        onCancel={() => { uBuilders.setIsAddingBuilder(false); form.resetFields(); }}
                        okButtonProps={{ style: { display: 'none' } }}
                      >
                        <BuilderAdd
                          form={form}
                          onAdd={() => {
                            uBuilders.setIsAddingBuilder(false);
                            uBuilders.loadBuilders();
                          }}
                        />
                      </Modal>

                    </li>
                    <li className="nav-item dropdown">
                      <YcDropdownMenu />
                    </li>
                  </>
                )}
              {uAuths.isLogin() === false
                && (
                  <>
                    <li className="nav-item">
                      <Link to="/login">Login / Sign up</Link>
                    </li>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={`Edit Builder ${builderSelected?.name}`}
        open={!!builderSelected}
        onOk={() => {
          if (builderSelected) {
            uBuilders.editBuilder(builderSelected.id, { userToken: newConfBuilder });
            _setBuilderSelected(null);
            _setNewConfBuilder('');
          }
        }}
        onCancel={() => { _setBuilderSelected(null); _setNewConfBuilder(''); }}
      >
        <label>Token</label>
        <Input placeholder="New token" value={newConfBuilder} onChange={(e) => { _setNewConfBuilder(e.target.value); }} />
      </Modal>
    </nav>
  );
}
