import { MCreation } from '../modeles';
import { BaseService } from './base.service';

class MidjourneyService extends BaseService {
  PATH = 'midjourney/';

  async login(data: { login: string, password: string }): Promise<any | null> {
    return this._edit(`${this.PATH}login`, data);
  }

  async generate(builderId: string, workspaceId: string, data: { prompt: string }): Promise<any | null> {
    return this._edit(`${this.PATH}generate/${builderId}/${workspaceId}`, data);
  }

  async creations(builderId: string): Promise<MCreation[] | null> {
    return this._view(`${this.PATH}creations/${builderId}`);
  }

  async guilds(userToken: string): Promise<any[] | null> {
    return this._listingPost(`${this.PATH}guilds`, {
      userToken,
    });
  }

  async channels(userToken: string, guildId: string): Promise<any[] | null> {
    return this._listingPost(`${this.PATH}channels`, {
      userToken,
      guildId,
    });
  }

  async deleteCreative(id: string, creationId: number): Promise<null> {
    return this._delete(`${this.PATH}delete/${id}/${creationId}`);
  }

  async interaction(builderId: number, workspaceId: number, data: { type: number, id: number, customId: string }): Promise<any | null> {
    const res = await this._edit(`${this.PATH}interaction/${builderId}/${workspaceId}`, data);
    console.log(res);
    return res;
  }
}

export const midjourneyService = new MidjourneyService();
