/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import {
  builderService, dalleService, midjourneyService, stabilityAiService, workspaceService,
} from '../services';
import { MBuilder, MCreation_Component, MWorkspace } from '../modeles';

export interface BuilderContextType {
  isLoading: boolean,

  // builder
  builder: MBuilder | null,
  loadBuilder: (id: string, creationId: string) => Promise<MBuilder | null>;

  workspace: MWorkspace | null,
  setWorkspaceById: (id: string) => void;
  addWorkspace: (name: string) => Promise<MWorkspace | null>;

  setPrompt: (prompt: string) => void;

  // creations
  deleteCreation: (creationId: number) => Promise<void>;
  generate: () => void;
  runInteraction: (id: number, component: MCreation_Component) => void;
}

const BuilderContext = React.createContext<BuilderContextType>({
  isLoading: false,

  builder: null,
  loadBuilder: (/* id: string, creationId: string */) => { return Promise.reject(); },

  workspace: null,
  setWorkspaceById: (/* id: string */) => { },
  addWorkspace: (/* name: string */) => { return Promise.reject(); },

  setPrompt: (/* prompt: string */) => {},

  deleteCreation: (/* prompt: string */) => { return Promise.reject(); },
  generate: () => {},
  runInteraction: () => {},
});

export function BuilderContextProvider({ children }: { children: React.ReactNode }) {
  const uHistory = useHistory();
  const [isLoading, _setIsLoading] = useState<boolean>(false);
  const [timeout, _setTimeout] = useState<NodeJS.Timeout | null>(null);
  const [builder, _setBuilder] = useState<MBuilder | null>(null);
  const [workspace, _setWorkspace] = useState<MWorkspace | null>(null);
  const [prompt, _setPrompt] = useState<string>('');

  const setPrompt = useCallback((newPrompt: string) => {
    _setPrompt(newPrompt.replace(/\s+/g, ' ').trim());
  }, []);

  const generate = useCallback(async () => {
    _setIsLoading(true);
    if (prompt && builder && workspace) {
      if (builder.type === 'MIDJOURNEY') {
        await midjourneyService.generate(builder.id.toString(), workspace!.id.toString(), {
          prompt,
        }).then(async (/* response: any */) => {
          _setIsLoading(false);
          await loadBuilder(builder.id.toString(), workspace.id.toString());
        });
      } else if (builder.type === 'DALL_E_2') {
        await dalleService.generate(builder.id.toString(), workspace!.id.toString(), {
          prompt,
        }).then(async (/* response: any */) => {
          _setIsLoading(false);
          await loadBuilder(builder.id.toString(), workspace.id.toString());
        });
      } else if (builder.type === 'STABILITYAI') {
        await stabilityAiService.generate(builder.id.toString(), workspace!.id.toString(), {
          prompt,
        }).then(async (/* response: any */) => {
          _setIsLoading(false);
          await loadBuilder(builder.id.toString(), workspace.id.toString());
        });
      }
    } else {
      console.warn('No builder or workspace found');
      _setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prompt, builder]);

  const runInteraction = useCallback(async (id: number, component: MCreation_Component) => {
    _setIsLoading(true);
    if (id && component && workspace && builder) {
      await midjourneyService.interaction(builder.id, workspace?.id, { id, type: component.type, customId: component.custom_id || '' });
      await loadBuilder(builder.id.toString(), workspace.id.toString());
    }
    _setIsLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builder, workspace]);

  const _clearTimeout = useCallback(() => {
    if (timeout) {
      clearTimeout(timeout);
      _setTimeout(null);
    }
  }, [timeout, _setTimeout]);

  const loadBuilder = useCallback((bid: string, workspaceId: string = '') => {
    return builderService.view(bid).then((build: MBuilder | null) => {
      _clearTimeout();
      _setBuilder(build);
      if (build) {
        let wsp = build.WorkspaceDefault;
        if (workspaceId) {
          wsp = build!.Workspaces.find((w) => w.id === parseInt(workspaceId, 10)) || build.WorkspaceDefault;
        }
        _setWorkspace(wsp);
        if (build.needReload) {
          _setTimeout(setTimeout(() => {
            loadBuilder(build!.id.toString(), workspaceId);
          }, 5000));
        }
      }
      _setIsLoading(false);
      return build;
    });
  }, [_clearTimeout]);

  const deleteCreation = useCallback(async (creationId: number) => {
    _setIsLoading(true);
    if (builder && creationId) {
      if (builder.type === 'MIDJOURNEY') {
        await midjourneyService.deleteCreative(builder.id.toString(), creationId);
      } else if (builder.type === 'DALL_E_2') {
        await dalleService.delete(creationId.toString());
      } else if (builder.type === 'STABILITYAI') {
        await stabilityAiService.delete(creationId.toString());
      }
      loadBuilder(builder.id.toString(), workspace?.id.toString());
    }
  }, [builder, loadBuilder, workspace?.id]);

  const value = {
    isLoading,

    builder,
    loadBuilder,

    workspace,
    setWorkspaceById: useCallback((id: string) => {
      if (builder) {
        const ws = builder!.Workspaces.find((w) => w.id === parseInt(id, 10));
        _setWorkspace(ws || null);
        if (timeout) {
          _clearTimeout();
          _setTimeout(setTimeout(() => {
            loadBuilder(builder!.id.toString(), ws!.id.toString());
          }, 5000));
        }
      }
    }, [builder, loadBuilder, timeout, _clearTimeout]),

    addWorkspace: useCallback((name: string) => {
      return workspaceService.add(builder!.id.toString(), name).then((wk: MWorkspace | null) => {
        if (builder && wk) {
          _setBuilder({
            ...builder,
            Workspaces: [...builder.Workspaces, wk],
          });
          uHistory.push(`/builder/${builder.id}/${wk.id}`);
        }
        return wk;
      });
    }, [builder, uHistory]),
    setPrompt,

    deleteCreation,
    generate,
    runInteraction,
  };

  return (
    <BuilderContext.Provider value={value}>
      {children}
    </BuilderContext.Provider>
  );
}

export function useBuilder() {
  const context = React.useContext(BuilderContext);
  if (!context) throw new Error('No BuilderContext provider found!');
  return context;
}
