/* eslint-disable jsx-a11y/control-has-associated-label */

import {
  Button, Form, Input,
} from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { BuilderTypes, BuilderTypes_Array } from '../../enums';
import { MBuilder } from '../../modeles';

import './builder-add.scss';
import { useBuilders } from '../../contexts';
import { BuilderFormMidjourney } from '../builder-form-midjourney';
import { BuilderFormDalle } from '../builder-form-dalle';
import { BuilderFormStabilityAi } from '../builder-form-stabilityai';

export function BuilderAdd(props: {
  // eslint-disable-next-line react/no-unused-prop-types
  onAdd?: () => void,
  form: any
}) {
  const uBuilders = useBuilders();
  const uHistory = useHistory();
  const [step, _setStep] = useState(0);
  const [loading, _setLoading] = useState(false);
  const [builder, _setBuilder] = useState(new MBuilder());
  const [configDetail, _setConfigDetail] = useState({});

  const setConfigDetail = (data: object) => {
    _setConfigDetail(data);
  };

  const onFinish = (data: any) => {
    const post = { ...builder, name: data.name, config: { ...data.config, ...configDetail } };
    _setLoading(true);
    uBuilders.addBuilder(post).then((result: MBuilder | null) => {
      _setLoading(false);
      if (result) {
        _setStep(0);
        props.form.resetFields();
        _setBuilder(new MBuilder());
        if (props.onAdd) {
          props.onAdd();
        }
        uHistory.push(`/builder/${result.id}`);
      }
    });
  };

  return (
    <div id="builder-add">
      {step === 0 && (
      <div className="row">
        {BuilderTypes_Array.map((e) => {
          return (
            <div className="col-4" key={e}>
              <Button
                className="builder"
                onClick={() => {
                  _setStep(1);
                  _setBuilder({ ...builder, type: e });
                }}
              >
                {e}
              </Button>
            </div>
          );
        })}
      </div>
      )}
      {step === 1 && (
      <div>
        <div>
          <Form form={props.form} layout="vertical" onFinish={onFinish}>
            <Form.Item
              rules={[{
                required: true,
                message: 'Name is required',
              },
              ]}
              label="Name"
              name="name"
            >
              <Input placeholder="" />
            </Form.Item>
            {builder.type === BuilderTypes.MIDJOURNEY && (
              <BuilderFormMidjourney form={props.form} step={step} setConfigDetail={setConfigDetail} />
            )}
            {builder.type === BuilderTypes.DALL_E_2 && (
              <BuilderFormDalle step={step} setConfigDetail={setConfigDetail} />
            )}
            {builder.type === BuilderTypes.STABILITYAI && (
              <BuilderFormStabilityAi step={step} setConfigDetail={setConfigDetail} />
            )}
            <Button type="primary" htmlType="submit" loading={loading}>Create</Button>
          </Form>
        </div>
      </div>
      )}
    </div>
  );
}
