import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { CreativesPreview, PromptZone } from '../../components';

import './midjourney.page.scss';
import { WorkspaceList } from '../../../../components';
import { useBuilder } from '../../../../contexts/builder.context';
import { MBuilder } from '../../../../modeles';

export function MidjourneyPage() {
  const uBuilder = useBuilder();
  const uHistory = useHistory();
  const uParams = useParams<{
    id: string;
    workspaceId: string;
  }>();
  const [promptIsVisible, setPromptIsVisible] = useState<boolean>(true);
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const handleScroll = () => {
    const element = document.getElementById('creatives-previews');
    const currentPosition = element!.scrollTop as number;

    if (currentPosition > scrollPosition) {
      setPromptIsVisible(false);
    } else {
      setPromptIsVisible(true);
    }

    setScrollPosition(currentPosition);
  };

  const loadBuilder = async () => {
    if (!uBuilder.builder || uBuilder.builder.id !== parseInt(uParams.id, 10)) {
      await uBuilder.loadBuilder(uParams.id, uParams.workspaceId).then((builder: MBuilder | null) => {
        if (builder == null) {
          uHistory.push('/');
        }
        return builder;
      });
    } else {
      uBuilder.setWorkspaceById(uParams.workspaceId || uBuilder.builder.WorkspaceDefault.id.toString());
    }
  };

  useEffect(() => {
    loadBuilder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uParams]);

  return (
    <div id="builder">
      <WorkspaceList />
      <PromptZone className={`prompt ${!promptIsVisible && 'hide'}`} />
      <CreativesPreview handleScroll={handleScroll} className="grid" />
    </div>
  );
}
