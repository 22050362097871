/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  Dropdown, MenuProps, Image, Modal, Form,
} from 'antd';
import {
  DownOutlined,
  LogoutOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useState } from 'react';

import { useAuths } from '../../contexts/auths.context';
import { StarLiva } from '../../assets/images/placeolder';

// styles
import './dropdown-menu.scss';
import { MDiscord } from '../../modeles';

export function YcDropdownMenu() {
  const uAuths = useAuths();
  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    console.log('click', e);
  };

  const items: MenuProps['items'] = [
    {
      label: 'Config',
      key: 'config',
      icon: <SettingOutlined />,
      onClick: () => { showModal(); },
    },
    {
      label: 'Log out',
      key: 'logout',
      icon: <LogoutOutlined />,
      style: { color: 'red' },
      onClick: () => { uAuths.logout(); },
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const onFinish = (values: MDiscord) => {
    uAuths.setDiscord(values);
    setIsModalOpen(false);
  };

  return (
    <div id="dropdown-menu">
      <Dropdown.Button menu={menuProps} placement="bottomRight" icon={<DownOutlined />}>
        <div className="profil">
          <Image
            preview={false}
            width={30}
            src={StarLiva}
          />
          <div className="informations">
            <p className="name">{uAuths.user?.Account.name}</p>
          </div>
        </div>
      </Dropdown.Button>
      <Modal
        title="Builder Config"
        open={isModalOpen}
        onOk={() => form.submit()}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={() => { }}
        >
          -- Settings WIP --
        </Form>
      </Modal>
    </div>
  );
}
