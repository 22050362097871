import {
  Button, Image, Modal, Spin,
} from 'antd';
import { DeleteOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons';

import { useState } from 'react';
import { MCreation, MCreation_Component } from '../../../../modeles';
import { Waitingtostart } from '../../../../assets/images/placeolder';
import { useBuilder } from '../../../../contexts/builder.context';
import bkg from '../../../../assets/images/placeolder/bkg.jpg';

import './creative-preview.scss';

export function CreativePreview(
  props: {
    creation: MCreation,
  },
) {
  const uBuilder = useBuilder();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 32, color: 'white' }} spin />;

  const downloadFile = (creation: MCreation) => {
    if (creation && creation.data && creation.data.url) {
      fetch(creation.data.url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${creation.data!.content}.png`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteCreation = async (id: number) => {
    await uBuilder.deleteCreation(id);
  };

  const runInteraction = (id: number, component: MCreation_Component) => {
    uBuilder.runInteraction(id, component);
    setIsModalOpen(false);
  };

  return (
    <div className="col-xl-2 col-lg-3 col-md-4 col-6">
      {props.creation.data && (
        <>
          <div className="creative-preview">
            <img className="bg" src={bkg} alt="bg" />
            <div className="content-creative">
              <>
                <Spin spinning={!props.creation.isDone} size="large" indicator={antIcon}>
                  <Image
                    onClick={() => showModal()}
                    preview={false}
                    className="card-content"
                    src={props.creation.data!.url ? props.creation.data!.url : Waitingtostart}
                  />
                  <div className="card-footer">
                    <div className="content">{props.creation.prompt}</div>
                    <DownloadOutlined disabled={!props.creation.isDone} style={{ color: '#0068F7' }} onClick={() => downloadFile(props.creation)} />
                  </div>
                </Spin>
              </>
            </div>
          </div>
          <Modal
            open={isModalOpen}
            width={1100}
            onCancel={handleCancel}
            footer={null}
          >
            <div className="component-modal">
              <div className="row listbt">
                <div className="col-lg-8 col-sm-7 col-xs-12">

                  <div className="creative-preview">
                    <img className="bg" src={bkg} alt="bg" />
                    <div className="content-creative">
                      <Image
                        className="image"
                        src={props.creation.data.url ? props.creation.data!.url : Waitingtostart}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-5 col-xs-12">

                  <div className="more-options row">
                    <div className="col-12">
                      <h4 className="title">More options</h4>

                      <div className="content">
                        <Spin spinning={uBuilder.isLoading}>
                          <div className="component-list">
                            {props.creation.data?.components?.filter((el) => !!el.custom_id)?.map((component, index) => (
                              <div className="content-component">
                                <div
                                  key={index}
                                  className={`component ${component.label ? '' : 'emoji'}`}
                                  onClick={() => { runInteraction(props.creation.id, component); }}
                                >
                                  <span role="img" aria-label="thumbs up">{component.emoji?.name}</span>
                                  <span className="label">
                                    {component.label}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Spin>
                        <div className="actions">
                          <Button
                            type="primary"
                            ghost
                            icon={<DownloadOutlined />}
                            shape="round"
                            loading={uBuilder.isLoading}
                            onClick={() => downloadFile(props.creation)}
                          >
                            Download
                          </Button>
                          <Button
                            danger
                            ghost
                            icon={<DeleteOutlined />}
                            shape="round"
                            loading={uBuilder.isLoading}
                            onClick={() => { deleteCreation(props.creation.id); }}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </Modal>
        </>
      )}
    </div>

  );
}
