import { BaseService } from './base.service';

class DalleService extends BaseService {
  PATH = 'dalle/';

  async checkToken(token: string): Promise<any | null> {
    console.log('Checking token');
    return this._view(`${this.PATH}check-token/${token}`);
  }

  async generate(builderId: string, workspaceId: string, data: { prompt: string }): Promise<any | null> {
    return this._edit(`${this.PATH}generate/${builderId}/${workspaceId}`, data);
  }

  async delete(creationId: string): Promise<any | null> {
    return this._delete(`${this.PATH}/${creationId}`);
  }
}

export const dalleService = new DalleService();
