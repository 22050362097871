/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  useMemo, useState, useCallback, useEffect,
} from 'react';

import { MPromptOption, MPromptOptionElement } from '../../../modeles/promptOption.m';
import promptOptionsJson from '../../../constant/prompt-options.json';
import { PromptOptionIcons } from '../../../enums';

export interface PromptOptionContextType {
  promptOptions: MPromptOption[],
  optionBeforePrompt: string,
  optionAfterPrompt: string,
  setPromptOptions: (data: MPromptOption[]) => void;
  selectedElements: (option: MPromptOption) => MPromptOptionElement[];
  clearAll: () =>void;
}

const PromptOptionContext = React.createContext<PromptOptionContextType>({
  promptOptions: [],
  optionBeforePrompt: '',
  optionAfterPrompt: '',
  setPromptOptions: () => {},
  selectedElements: () => { return []; },
  clearAll: () => { },
});

export function PromptOptionContextProvider({ children }: { children: React.ReactNode }) {
  const [optionBeforePrompt, _setOptionBeforePrompt] = useState<string>('');
  const [optionAfterPrompt, _setOptionAfterPrompt] = useState<string>('');
  const [promptOptions, _setPromptOptions] = useState<MPromptOption[]>([]);

  useEffect(() => {
    const newPromptOptions: MPromptOption[] = [];
    promptOptionsJson.styles.forEach((style) => {
      const newStyle: MPromptOption = {
        ...style,
        icon: PromptOptionIcons[style.icon],
        elements: style.elements.map((element) => {
          return { ...element, isSelected: false, intensity: style.isSystem ? 0 : 1 };
        }),
      };

      newPromptOptions.push(newStyle);
    });
    setPromptOptions(newPromptOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newStringAfter = '';
    let newStringBefore = '';
    let excludeElements = '';

    promptOptions.filter((option) => option.isSystem === false).forEach((option) => {
      const elementsSelected = option.elements?.filter((element) => element.isSelected);
      elementsSelected?.forEach((elementSelected) => {
        if (!elementSelected.isExclude) {
          if (option.isBeforePrompt === false) {
            newStringAfter = `${newStringAfter} ${option.prefix} ${elementSelected.value} ${option.sufix}${option.hasIntensity
              ? `::${elementSelected.intensity}` : ''}`;
          } else {
            newStringBefore = `${newStringBefore} ${option.prefix} ${elementSelected.value} ${option.sufix}${option.hasIntensity
              ? `::${elementSelected.intensity}` : ''}`;
          }
        } else {
          excludeElements += `(--no ${elementSelected.value})`;
        }
      });
    });

    newStringAfter += excludeElements;
    promptOptions.filter((option) => option.isSystem === true).forEach((option) => {
      const elementsSelected = option.elements?.filter((element) => element.isSelected);
      elementsSelected?.forEach((elementSelected) => {
        newStringAfter = `${newStringAfter} ${option.prefix} ${elementSelected.value} ${elementSelected.intensity > 0
          ? elementSelected.intensity : ''} ${option.sufix}`;
      });
    });

    _setOptionBeforePrompt(newStringBefore);
    _setOptionAfterPrompt(newStringAfter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promptOptions]);

  const setPromptOptions = useCallback((data: MPromptOption[]) => {
    _setPromptOptions(data);
  }, []);

  const selectedElements = useCallback((option: MPromptOption) => {
    return option.elements?.filter((element) => element.isSelected) || [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promptOptions]);

  const clearAll = useCallback(() => {
    const newOptions = [...promptOptions];
    newOptions.forEach((option) => {
      option.elements?.forEach((element) => {
        element.isSelected = false;
      });
    });
    setPromptOptions(newOptions);
  }, [promptOptions, setPromptOptions]);

  const value = useMemo(() => ({
    promptOptions,
    optionBeforePrompt,
    optionAfterPrompt,
    setPromptOptions,
    selectedElements,
    clearAll,
  }), [
    promptOptions,
    optionBeforePrompt,
    optionAfterPrompt,
    setPromptOptions,
    selectedElements,
    clearAll,
  ]);

  return (
    <PromptOptionContext.Provider value={value}>
      {children}
    </PromptOptionContext.Provider>
  );
}

export function usePromptOption() {
  const context = React.useContext(PromptOptionContext);
  if (!context) throw new Error('No PromptOptionContext provider found!');
  return context;
}
