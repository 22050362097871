import { MBuilder } from '../modeles';
import { BaseService } from './base.service';

class BuilderService extends BaseService {
  PATH = 'builder/';

  async view(id: string): Promise<MBuilder | null> {
    return this._view(`${this.PATH}view/${id}`);
  }

  async add(data: MBuilder): Promise<MBuilder | null> {
    return this._add(`${this.PATH}add`, data);
  }

  async edit(data: { id: number, config: any }): Promise<any | null> {
    return this._edit(`${this.PATH}edit`, data);
  }

  async list(): Promise<MBuilder[]> {
    return this._listing(`${this.PATH}list`);
  }
}

export const builderService = new BuilderService();
