import { Link } from 'react-router-dom';

import { BackgroundStarliva } from '../../../../assets/images/home';
import { useAuths } from '../../../../contexts';

import './home.page.scss';

export function HomePage() {
  const uAuths = useAuths();
  const ycIsLogin = uAuths.isLogin();

  return (
    <div id="root-home">

      <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/26656886.js" />

      <div className="content row">

        <div className="col-lg-6">
          <div className="img-htop">
            <img className="img-home" title="Starliva" alt="Starliva" src={BackgroundStarliva} />
          </div>
          <div className="content-block">
            <h1 className="title">Generate Midjourney images <b>more easily</b></h1>
            <Link to={ycIsLogin ? '/builder' : '/login'}>
              <button className="button" type="button">
                Try Starliva builder
              </button>
            </Link>
          </div>
        </div>

        <div className="col-lg-6 img-hbottom">
          <img className="img-home" title="Starliva" alt="Starliva" src={BackgroundStarliva} />
        </div>
      </div>

    </div>
  );
}
