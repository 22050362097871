import * as icons from '../assets/images/options-ico';

export const PromptOptionIcons: { [key: string]: string } = {
  artist: icons.Artist,
  style: icons.Style,
  elements: icons.Elements,
  lights: icons.Lighting,
  color: icons.Colors,
  size: icons.Size,
  variants: icons.Variation,
  focus: icons.Focus,
  fidelity: icons.Fidelity,
  cameras: icons.Camera,
  details: icons.Details,
  material: icons.Material,
  layout: icons.Layout,
};
