import {
  Redirect,
  Route, Switch, useRouteMatch,
} from 'react-router-dom';

import {
  MidjourneyPage,
} from './pages';

import './index.scss';
import { PromptContextProvider } from './contexts/prompt.context';
import { PromptOptionContextProvider } from './contexts/prompt-option.context';
import { useBuilders } from '../../contexts';

export function BuilderModule() {
  const uBuilders = useBuilders();
  const { path } = useRouteMatch();

  const redirect = () => {
    if (uBuilders.builders.length > 0) {
      return <Redirect to={`${path}/${uBuilders.builders[0].id}`} />;
    }
    uBuilders.setIsAddingBuilder(true);
    return <Redirect to="/" />;
  };

  return (
    <div id="builder">
      <PromptOptionContextProvider>
        <PromptContextProvider>
          <Switch>
            <Route exact path={`${path}/:id/:workspaceId`}><MidjourneyPage /></Route>
            <Route exact path={`${path}/:id`}><MidjourneyPage /></Route>
            <Route exact path={path} render={() => redirect()} />
          </Switch>
        </PromptContextProvider>
      </PromptOptionContextProvider>
    </div>
  );
}
