import {
  Form, Input, Button,
} from 'antd';
import { Link } from 'react-router-dom';

// import * as ImagesSignup from '../../../../assets/images/sign-up';
import { useAuths } from '../../../../contexts/auths.context';

// styles
import './sign-up.page.scss';

export function SignUpPage() {
  const uAuths = useAuths();

  const onFinish = async (data: any) => {
    await uAuths.signup(data);
  };

  return (
    <div className="root-sign-up">
      <div className="block-bkg">
        <div className="block-auth">
          {!uAuths.signUp && (
          <>
            <h1>Sign Up</h1>
            <Form onFinish={(e) => { onFinish(e); }} layout="vertical">
              <Form.Item label="Account Name" name="name" rules={[{ required: true }]} initialValue="">
                <Input placeholder="Account Name" type="text" />
              </Form.Item>
              <Form.Item label="Email" name={['user', 'email']} rules={[{ required: true }]} initialValue="">
                <Input placeholder="Email" type="email" />
              </Form.Item>
              <Form.Item label="Password" name={['user', 'password']} rules={[{ required: true }]} initialValue="">
                <Input.Password placeholder="Password" type="password" />
              </Form.Item>
              <Form.Item style={{ marginBottom: '0px' }}>
                <Button type="primary" htmlType="submit">Sign Up</Button>
                <Link className="pl-3" to="/login">Login</Link>
              </Form.Item>
            </Form>
          </>
          )}
        </div>
      </div>
    </div>
  );
}
