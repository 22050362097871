import './prompt-zone.scss';
import { PromptInput } from '../prompt-input';
import { PromptOptions } from '../prompt-options';

export function PromptZone(props: { className?: string; }) {
  return (
    <div id="prompt-zone" className={props.className}>
      <PromptOptions />
      <PromptInput />
    </div>
  );
}
