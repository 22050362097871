import { Button, Input, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';

import { useBuilder } from '../../../../contexts/builder.context';
import { usePrompt } from '../../contexts/prompt.context';

import './prompt-input.scss';

export function PromptInput(props: { className?: string; }) {
  const [messageApi, contextHolder] = message.useMessage();
  const uPrompt = usePrompt();
  const uBuilder = useBuilder();

  const promptChange = (newPrompt: string) => {
    uPrompt.setPrompt(newPrompt);
  };

  const generate = () => {
    uBuilder.generate();
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(uPrompt.complexPrompt || '');
    messageApi.info('Prompt successfully copied!');
  };

  return (
    <div id="prompt-input" className={props.className}>
      {contextHolder}
      <div className="prompt">
        <Input className="input" placeholder="Start typing your main idea" onChange={(e) => promptChange(e.target.value as string)} />
        <Button className="button" type="primary" shape="round" onClick={generate} loading={uBuilder.isLoading}>
          Generate
        </Button>
      </div>
      <div className="copy">
        <TextArea
          className="text-area"
          rows={3}
          style={{ resize: 'none' }}
          bordered={false}
          disabled
          value={uPrompt.complexPrompt || ''}
        />
        <Button className="button" type="link" icon={<CopyOutlined />} onClick={copyToClipboard} />
      </div>
    </div>
  );
}
