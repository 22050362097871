import {
  Switch, Route, Redirect, RouteProps, useLocation,
} from 'react-router-dom';
import { Image } from 'antd';
import { useEffect, useState } from 'react';

import { YcHeader } from '../components';
import { useAuths } from '../contexts';
import packageJson from '../../package.json';
import { BackgroundAssets } from '../assets/images/home';

// Modules
import { BuilderModule } from '.';

// Pages
import {
  HomePage, LoginPage, SignUpPage,
} from './root/pages';

import './app.scss';

const frontVersion = packageJson.version;

function YcRoute({
  YcComponent, ycIsLogin, canAccess = true, ...rest
}: RouteProps<string> & { YcComponent: any, ycIsLogin?: boolean, canAccess?: boolean }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {(ycIsLogin && canAccess) && <YcComponent {...props} />}
          {(!ycIsLogin || !canAccess) && <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
        </>
      )}
    />
  );
}

export function App() {
  const uAuths = useAuths();
  // const ycIsSuperAdmin = uAuths.isSuperAdmin();
  const location = useLocation();
  const [isHomePath, setIsHomePath] = useState<boolean>(false);
  const pathToCheck = ['login', 'sign-up', 'forgot-password'];

  useEffect(() => {
    setIsHomePath(location.pathname === '/' || pathToCheck.some((str) => location.pathname.includes(str)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div id="app">
      <label className="app-version">V{frontVersion}</label>
      <YcHeader isHomePath={isHomePath} />
      {uAuths.isReady() && (
        <div className="main">
          {isHomePath
          && (
          <div className="background-home">
            <Image
              preview={false}
              src={BackgroundAssets}
            />
          </div>
          )}
          <div className="content">
            <Switch>
              {/* Logout route */}
              <YcRoute path="/login" ycIsLogin={!uAuths.isLogin()} YcComponent={LoginPage} />
              <YcRoute path="/sign-up" ycIsLogin={!uAuths.isLogin()} YcComponent={SignUpPage} />

              {/* Modules */}
              <YcRoute path="/builder" ycIsLogin={uAuths.isLogin()} YcComponent={BuilderModule} />

              <Route exact path="/"><HomePage /></Route>

            </Switch>
          </div>
        </div>
      )}
    </div>
  );
}
