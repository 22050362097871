/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState } from 'react';
import { builderService } from '../services';

import {
  MBuilder,
} from '../modeles';

export interface BuildersContextType {
  builders: MBuilder[],
  isAddingBuilder: boolean,
  loadBuilders: () => void;
  editBuilder: (id: number, conf: any) => void;
  setIsAddingBuilder: (isAdding: boolean) => void;
  addBuilder: (builder: MBuilder) => Promise<MBuilder | null>;
}

const BuildersContext = React.createContext<BuildersContextType>({
  builders: [],
  isAddingBuilder: false,
  loadBuilders: () => {},
  editBuilder: () => {},
  setIsAddingBuilder: () => {},
  addBuilder: async (/* builder: MBuilder */) => { return Promise.reject(); },

});

export function BuildersContextProvider({ children }: { children: React.ReactNode }) {
  const [builders, _setBuilders] = useState<MBuilder[]>([]);
  const [isAddingBuilder, _setIsAddingBuilder] = useState<boolean>(false);

  const loadBuilders = () => {
    builderService.list().then((bls: MBuilder[]) => {
      _setBuilders(bls);
    });
  };
  const addBuilder = async (builder: MBuilder) => {
    return builderService.add(builder).then((data: MBuilder | null) => {
      if (data) {
        loadBuilders();
      }
      return data;
    });
  };
  const setIsAddingBuilder = (isAdding: boolean) => {
    _setIsAddingBuilder(isAdding);
  };
  const editBuilder = (id: number, config: any) => {
    builderService.edit({ id, config }).then((builder: any) => {
      console.log('builder: ', builder);
    });
  };
  return (
    <BuildersContext.Provider value={{
      builders,
      isAddingBuilder,
      loadBuilders,
      editBuilder,
      setIsAddingBuilder,
      addBuilder,
    }}
    >
      {children}
    </BuildersContext.Provider>
  );
}

export function useBuilders() {
  const context = React.useContext(BuildersContext);
  if (!context) throw new Error('No BuildersContext provider found!');
  return context;
}
