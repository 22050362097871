import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

// styles
import './date-picker.scss';

const { RangePicker } = DatePicker;

export function YcDatePicker(props: {
  // eslint-disable-next-line react/no-unused-prop-types
  value: [dayjs.Dayjs, dayjs.Dayjs],
  onChange: (e: any) => void,
}) {
  const [open, _setOpen] = useState(false);

  function GetValue() {
    if (open === false) {
      return props.value;
    }
    return null;
  }

  const OnChange = (e: any) => {
    props.onChange(e);
    _setOpen(false);
  };

  const rangePresets: {
    label: string;
    value: [Dayjs, Dayjs];
  }[] = [
    { label: 'Today', value: [dayjs(), dayjs()] },
    { label: 'Yesterday', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
    { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
  ];

  return (
    <span className="c-datepicker">
      <RangePicker
        value={GetValue()}
        onChange={OnChange}
        presets={rangePresets}
        disabledDate={(d) => !d || d.isAfter(dayjs())}
      />
    </span>
  );
}
