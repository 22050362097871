import React from 'react';
import ReactDOM, { Container } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { App } from './modules/app';
import reportWebVitals from './reportWebVitals';

import { AuthsContextProvider, BuilderContextProvider, BuildersContextProvider } from './contexts';

ReactDOM.render(
  <React.StrictMode>

    <BrowserRouter>
      <AuthsContextProvider>
        <BuildersContextProvider>
          <BuilderContextProvider>
            <App />
          </BuilderContextProvider>
        </BuildersContextProvider>
      </AuthsContextProvider>
    </BrowserRouter>

  </React.StrictMode>,
  document.getElementById('root') as Container,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
