import { useState } from 'react';
import {
  Button, Input, Slider, Switch, message,
} from 'antd';
import {
  CloseOutlined, EyeOutlined, EyeInvisibleOutlined, CheckOutlined, StopOutlined,
} from '@ant-design/icons';

import './modal-elements.scss';
import { MPromptOption } from '../../../../../modeles/promptOption.m';

export function ModalElements(props: { option: MPromptOption, updateData: () => void, className?: string; }) {
  const [newElement, _setNewElement] = useState<string>('');
  const [messageApi, contextHolder] = message.useMessage();

  const addElement = () => {
    if (!props.option.elements?.find((element) => element.name.toLocaleLowerCase() === newElement.toLocaleLowerCase())) {
      props.option.elements?.push({
        name: newElement,
        value: newElement,
        image: '',
        isSelected: true,
        intensity: 50,
        isExclude: false,
      });
      props.updateData();
    } else {
      messageApi.error(`${newElement} already exists!`);
    }
  };

  const removeElement = (name: string) => {
    const newElements = props.option.elements?.filter((el) => el.name !== name);
    props.option.elements = newElements;
    props.updateData();
  };

  return (
    <div className="listbt">
      <div id="modal-elements" className={props.className}>
        {contextHolder}

        <Input
          className="new-element"
          placeholder="Enter your element"
          value={newElement}
          onChange={(e) => _setNewElement(e.target.value as string)}
          suffix={(
            <Button type="text" onClick={() => addElement()}>Add element</Button>
        )}
        />
        {
        props.option.elements?.map((element) => (
          <div className={`element ${element.isExclude ? 'exclude' : ''}`} key={element.name}>
            <div>{element.name}</div>
            <div className="slider-value" onClick={(e) => e.stopPropagation()}>
              {
                element.isExclude
                  ? <EyeInvisibleOutlined />
                  : <EyeOutlined />
              }
              <Slider
                disabled={element.isExclude}
                className="slider"
                min={0}
                max={100}
                step={1}
                value={element.intensity}
                onChange={(e: number) => {
                  element.intensity = e;
                  props.updateData();
                }}
              />
            </div>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<StopOutlined />}
              defaultChecked
              onChange={() => {
                element.isExclude = !element.isExclude;
                props.updateData();
              }}
            />
            <Button shape="circle" icon={<CloseOutlined />} onClick={() => removeElement(element.name)} />
          </div>
        ))
      }
      </div>
    </div>
  );
}
