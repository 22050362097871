/* eslint-disable jsx-a11y/control-has-associated-label */

import {
  Button, Form, Input, Space,
} from 'antd';
import { useEffect, useState } from 'react';

import './builder-form-dalle.scss';
import { dalleService } from '../../services';

export function BuilderFormDalle(props: {
  step: number,
  setConfigDetail: (data: object) => void
}) {
  const [token, _setToken] = useState<string>('');
  const [isValidToken, _setIsValidToken] = useState<boolean>(false);

  useEffect(() => {
    if (props.step === 0) {
      _setToken('');
      _setIsValidToken(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.step]);

  return (
    <>
      <b><u>DallꞏE 2:</u></b>
      <Form.Item
        rules={[{
          required: true,
          message: 'Token is required',
        },
        ]}
        label="OpenAI Token"
        name={['config', 'token']}
        tooltip={(<p>https://platform.openai.com/account/api-keys</p>)}
      >
        <Space.Compact style={{ width: '100%' }}>
          <Input
            placeholder="sk-QuA4..."
            value={token}
            onChange={(e) => {
              _setToken(e.target.value as string);
              props.setConfigDetail({ token: e.target.value });
            }}
            disabled={isValidToken}
          />
          <Button
            type="primary"
            disabled={isValidToken}
            style={{ width: '30%' }}
            onClick={() => {
              console.log('check: ', token);
              dalleService.checkToken(token).then((isValid: boolean) => {
                console.log('isValid: ', isValid);
                _setIsValidToken(isValid);
              });
            }}
          >Check token
          </Button>
        </Space.Compact>
      </Form.Item>
    </>
  );
}
